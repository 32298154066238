import { Box, Container, Heading } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"

const Banner = ({ title, ingress, headerlinks, backgroundImage }) => {
  return (
    <div
      className="onf-banner"
      style={{ backgroundImage: `url(${backgroundImage?.fluid?.src})` }}
    >
      <Container
        maxW="1000px"
        paddingTop="5"
        paddingBottom="5"
        className="onf-banner-content"
      >
        <div>
          <Heading size="xl" marginBottom="5">
            {title}
          </Heading>
          {ingress && <div>{ingress}</div>}
          <Box marginTop="5">
            {headerlinks?.links?.map(hl => (
              <Box
                style={{
                  display: "inline-block",
                  lineHeight: "3",
                  whiteSpace: "nowrap",
                }}
                marginTop="5"
                marginRight="5"
                key={hl.link}
              >
                <Link to={hl.link === "pdf" ? "/Økonomisk analyse av reiselivet i Oppdal - inkludert hytteutbygging.pdf" : hl.link} className="onf-banner-link">
                  {hl.name}
                  {"  "}
                  <span className="onf-banner-link-caret">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                </Link>
              </Box>
            ))}
          </Box>
        </div>
      </Container>
    </div>
  )
}

Banner.propTypes = {
  title: PropTypes.string,
  ingress: PropTypes.string,
  headerlinks: PropTypes.object,
  backgroundImage: PropTypes.object,
}

export default Banner
