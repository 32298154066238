import {
  Box,
  Button,
  Divider,
  Heading,
  SimpleGrid,
  SlideFade,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import {
  changeLocale,
  FormattedMessage,
  Link,
  useIntl,
} from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React from "react"
import footerlogo from "../img/ONF_logo.png"
import Banner from "./banner"
import Header from "./header"
import ScrollTop from "./scrollTop"

const Layout = ({ children, container, banner }) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const intl = useIntl()
  const intlIsNo = intl.locale === "no"
  const isLight = colorMode === "light"
  // eslint-disable-next-line
  const rootPath = `${__PATH_PREFIX__}/`
  return (
    <>
      <Header />
      {banner && <Banner {...banner} />}
      <div className="sc-content-wrapper">
        <div className={`sc-content ${container ? "container" : ""}`}>
          <SlideFade in>
            <main>{children}</main>
          </SlideFade>
        </div>
      </div>
      <ScrollTop />
      <footer className="sc-footer-wrapper">
        <SimpleGrid
          marginLeft={["5", "5", "5", "20"]}
          marginRight={["5", "5", "5", "20"]}
          marginBottom="10"
          columns={[1, 1, 1, 4]}
          spacing={10}
        >
          <Box>
            <img style={{ maxWidth: "200px" }} src={footerlogo} />
          </Box>
          <Box>
            <Heading size="md">
              <FormattedMessage id="footerTermsHeader" />
            </Heading>
            <Divider marginTop="2" marginBottom="3" />
            <Text>
              <Link to="/privacy">
                <FormattedMessage id="footerPrivacy" />
              </Link>
            </Text>
          </Box>
          <Box>
            <Heading size="md">
              <FormattedMessage id="footerAboutUs" />
            </Heading>
            <Divider marginTop="2" marginBottom="3" />
            <Text>ONF Oppdal AS</Text>
            <Text>Org.nr. 859 419 712</Text>
            <Link href="https://www.facebook.com/onfoppdal">Facebook</Link>
          </Box>
          <Box>
            <Heading size="md">
              <FormattedMessage id="footerOptions" />
            </Heading>
            <Divider marginTop="2" marginBottom="3" />
            <Button
              marginRight="5"
              marginBottom={["10px", 0, 0, "10px"]}
              onClick={toggleColorMode}
            >
              {isLight ? (
                <FormattedMessage id="footerDarkMode" />
              ) : (
                <FormattedMessage id="footerLightMode" />
              )}
            </Button>

            <Button
              marginBottom={["10px", 0, 0, "10px"]}
              onClick={() => changeLocale(intlIsNo ? "en" : "no")}
            >
              {intlIsNo ? "Switch to english" : "Bytt til norsk"}
            </Button>
          </Box>
        </SimpleGrid>
        <Box textAlign="center" marginTop="5">
          {`© Oppdal Næringsforening ${new Date().getFullYear()}`}
        </Box>
      </footer>
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
  container: PropTypes.bool,
  banner: PropTypes.object,
}

export default Layout
