import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"

const ScrollTop = () => {
  const [visible, setVisible] = useState(false)
  let vindu
  useEffect(() => {
    if (typeof window !== `undefined`) {
      /* eslint-disable-next-line no-undef */
      vindu = window
      vindu?.addEventListener("scroll", toggleVisible)
    }
  }, [])

  const toggleVisible = () => {
    const scrolled = vindu?.document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    if (typeof window !== `undefined`) {
      /* eslint-disable-next-line no-undef */
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }
  }

  return (
    <button
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    >
      <FontAwesomeIcon className="scroll-top-icon" icon={faArrowUp} />
    </button>
  )
}

export default ScrollTop
